import React from "react";

const CallToAction = ({ title, action, link}) => {
    return (
        <div className="w-full  bg-lblue">
          <div className="max-w-4xl mx-auto px-4 py-20 items-center rounded-lg border-2 border-lblue">
                <h2 className="w-full block mx-auto text-center text-3xl text-dpurple nexaTextBold pb-5 sm:pb-9 px-24">
                  {title}
                </h2>
                <div className="mx-auto justify-center flex w-6/12">
                  <a href={link} className="pink-button w-auto">
                    {action}
                  </a>
                </div>
          </div>
        </div>
      );
}

export default CallToAction;