import React from "react";
import seller from "../images/sellerp.png";
import platform from "../images/platform.png";
import buyer from "../images/op.png";


const Services = () => {
  return (
      <div className="max-w-6xl mx-auto pt-10 pb-32">
        <h2 className="text-4xl text-bblue mx-auto w-fit pb-16 text-center">El punto de unión para emprendedores e inversores</h2>
        <div className="flex flex-col md:flex-row gap-6 items-center justify-center">
          {data.map((items, index) => {
            return (
              <div key={index} className="text-center pt-12 max-w-xs">
                <div className="bg-white border-2 border-dgrey rounded-lg px-4 md:px-7 pt-6">
                  <div className='flex flex-wrap md:block'>
                    <div className="relative mx-auto mb-6 md:mb-0 -top-20">
                      <div className="left-0 right-0">
                        <img src={items.image} alt={items.title} className='w-64 h-auto' />
                      </div>
                    </div>
                    <div className="relative mx-auto -top-14 md:-top-10">
                      <p className="text-xl text-dpurple nexabold pb-2">
                        {items.title}
                      </p>
                      <p className="text-base text-dpurple">
                        {items.description}
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
  );
};

export default Services;

const data = [
  {
    title: "Emprendedor",
    description: `Publica tu proyecto con sus métricas clave y recibe ofertas en poco tiempo.`,
    image: seller,
  },
  {
    title: "StartupOnSale",
    description: `Ponemos nuestra plataforma para que el proceso sea rápido y sencillo.`,
    image: platform,
  },
  {
    title: "Comprador",
    description: `Revisa los datos relevantes de los proyectos y encuentra justo el que necesitas.`,
    image: buyer,
  },
];
